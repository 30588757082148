import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<div className="content-container">
						<div className="office_inner">
							<h2 className={"large bold"}>
								放射温度計は、なぜ、触れずに測れるのだろう。
							</h2>
							<div class="temp05_magin">
								<h2 class="temp02_font_title medium">
									1.すべての物体は、赤外線を放射しています。
								</h2>
								<p class="medium">
									熱くなったアイロンの表面に手を近づけると、私たちは温かいと感じます。これは、アイロン表面から出ている赤外線を皮膚のセンサーが感じるからです。
									しかし、人間の皮膚は、物体の温度が低くなると赤外線をほとんど感知する事ができません。ところが生物のなかには高感度なセンサーをもっているものもあります。
									例えば、ガラガラヘビは、真っ暗闇のなかでも、動物が放射するわずかな赤外線を感知し獲物を捕獲します。
									図 1、2、3
									はアイロンが熱くなる過程をわかりやすく説明するためにイラスト化したものです。
									図のようにアイロンの温度が高くなるほど表面から強い赤外線がでていることがわかります。
									物体の温度と赤外線の関係は、１９世紀後半から２０世紀はじめにかけて多くの物理学者により研究されてきました。
									そして現在では、すべての物体が赤外線を放射し、放射の様子は、物体によってそれぞれ異なっていることが知られるようになりました。
								</p>
							</div>

							<div class="temp05_magin text_align_center">
								<img
									className="temp05_chart1"
									src="/asset/Illust07.gif"
									height="70"
									width="290"
									alt="熱エネルギーの運動が活発な状態"
								/>
							</div>
							<div class="temp05_magin text_align_center">
								<img
									className="temp05_chart2"
									src="/asset/Illust06.jpg"
									height="309"
									width="600"
									alt="熱エネルギーの運動が活発な状態"
								/>
							</div>
							<div class="temp05_magin">
								<p class="medium temp02_history_name text_align_left font_weight_600">
									すべての物体は赤外線を放射しています。この性質を利用して測温するのが放射温度計です。
								</p>
								<p class="medium">波長で見た赤外線</p>
							</div>
							<div class="temp05_magin text_align_center">
								<img
									className="temp05_chart3"
									src="/asset/chart03.gif"
									height="96"
									width="600"
									alt="熱エネルギーの運動が活発な状態"
								/>
							</div>

							<div class="flex-container temp05_energy_value">
								<div class="temp01_flex1 temp01_flex1_title">
									<div class="temp05_title_boder">
										<p class="medium temp01_font_title">
											2.見えない光の赤外線は、ハーシェルによって発見されました。
										</p>
									</div>
									<p class="medium">
										赤外線の発見者はイギリスの天文学者W.ハーシェルです。
										1800年、ハーシェルは太陽光をプリズムで分光し、各スペクトルの温度分布を調べているとき、赤光色の外側に、物体の温度を高くする目に見えない光があることに気づきました。
										これが赤外線の発見だったのです。赤外線は肉眼では見えませんが、光としての性質をもち、太陽をはじめ、すべての物体から放射されています。
										一般に光りといえば目に見える可視光線を指しますが、物理学の領域では目に見えない赤外線も光として考えています。
										したがって赤外線は可視光線と同じよように反射、屈折、回折などの性質をもっており、遠くの物体からでている赤外線を光学系で集光することもできるのです。
									</p>
								</div>
								<div class="temp01_flex2 temp01_flex2_title2">
									<div class="temp05_title_boder2">
										<p class="medium temp01_font_title">
											3.温度と赤外線の関係を、詳しく調べてみましょう。
										</p>
									</div>
									<p class="medium">
										前章までで、すべての物体がその温度に応じた赤外線エネルギーを放射していることを説明してきました。そして、物体の温度が変化すると、放射する赤外線エネルギーが変化することも見えてきました。ここでは、赤外線と温度の関係や、赤外線エネルギーがどのように変化するのか、ということをもう少しくわしく見てみましょう。
									</p>
								</div>
							</div>
						</div>
					</div>
					<PagingController current={5} next={'/support/documents/about-temperature/temp06'} prev={'/support/documents/about-temperature/temp04'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
